.moderate-popup-trigger {
  margin-top: 10px;
}

.moderate-popup-trigger a {
  color: #fff;
}

#moderatedModal .modal-content,
.moderate-list {
  height: auto !important;
}

.moderate-list::-webkit-scrollbar {
width: 8px;
}

.moderate-list::-webkit-scrollbar-track {
background: #f1f1f1;
}

.moderate-list::-webkit-scrollbar-thumb {
background: #FC1E2F;
border-radius: 8px;
}

#moderatedModal .session-speakers .sp-box {
  display: flex;
  height: 100%;
  background: #F0F2F6;
  padding: 10px 12px;
  border-radius: 5px;
  align-items: center;
}

#moderatedModal .session-speakers .sp-box img:not(.logo) {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

#moderatedModal .session-speakers .sp-box .sp-details {
  margin-left: 10px;
}

#moderatedModal .session-speakers .sp-box .sp-details a{
  color: #1B1F29;
  text-decoration: none !important;
}

#moderatedModal .session-speakers .sp-box .sp-details h4 {
  font-size: 16px;
  margin: 1px 0px 4px 0px;
  color: #1B1F29;
  font-weight: 600;
}
#moderatedModal .session-speakers .sp-box .sp-details p {
  font-size: 14px;
  color: #1B1F29;
  margin: 0px 0px;
}

#moderatedModal .session-speakers.featured .sp-box {
  width: 100%;
  flex: none;
  background: #fff;
  display: block;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.05);
}

#moderatedModal .session-speakers.featured .sp-box img:not(.logo) {
  border-radius: 5px;
  width: 100%;
  max-width: 150px;
  height: 110px;
  margin-bottom: 10px;
}

#moderatedModal .session-speakers.featured .sp-box .date {
  color: #58B859;
  font-size: 12px;
  margin: 0px 0px 7px 0px;
  font-weight: 500;
}

#moderatedModal .session-speakers.featured .sp-box h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

#moderatedModal .session-speakers.featured .sp-box .sp-details{
  margin-left: 0px;
}

#moderatedModal .session-speakers.featured .sp-box .logo{
  width: auto;
  height: 25px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 5px;
}

#moderatedModal .session-speakers:not(.featured) .row [class*="col-"] {
  padding-right: 0px !important;
  padding: 8px 10px 5px 15px;
  flex: 1;
  /*max-width: max-content;*/
}

#moderatedModal .session-speakers:not(.featured) .row [class*="col-"]:last-child{
  padding-right: 15px !important;
}

#moderatedModal .session-speakers{
  display: flex;
  flex-flow: wrap;
  row-gap: 10px;
  padding-bottom: 15px;
}

#moderatedModal .sp-box span{
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: bold;
}

