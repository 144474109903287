
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #eee;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #eee;
}

*::-webkit-scrollbar-thumb {
  background-color: #d03240;
  border-radius: 20px;
  border: 3px solid #eee;
}
.custom-scrollbar, * {
	scrollbar-color: #f96634 #a22b36 !important;
	scrollbar-width: thin;
}
body {
    background: #00293E;
    font-family: Arial;
}

.section {
    padding: 40px 0px;
}

.section.title {
    text-align: center;
}

.section.title h1 {
    color: #ACF1FF;
    font-size: 32px;
    font-weight: bold;
}

.section.title h2 {
    color: #fff;
    font-size: 21px;
    font-weight: normal;
}

.primary-btn {
    background: rgb(10, 155, 216);
    background: -moz-linear-gradient(130deg, rgba(10, 155, 216, 1) 0%, rgba(4, 184, 178, 1) 100%);
    background: -webkit-linear-gradient(130deg, rgba(10, 155, 216, 1) 0%, rgba(4, 184, 178, 1) 100%);
    background: linear-gradient(130deg, rgba(10, 155, 216, 1) 0%, rgba(4, 184, 178, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a9bd8", endColorstr="#04b8b2", GradientType=1);
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    border-radius: 40px;
    transition: all 0.5s ease;
}

.primary-btn:hover {
    background: rgb(10, 155, 216);
    background: -moz-linear-gradient(302deg, rgba(10, 155, 216, 1) 0%, rgba(4, 184, 178, 1) 100%);
    background: -webkit-linear-gradient(302deg, rgba(10, 155, 216, 1) 0%, rgba(4, 184, 178, 1) 100%);
    background: linear-gradient(302deg, rgba(10, 155, 216, 1) 0%, rgba(4, 184, 178, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a9bd8", endColorstr="#04b8b2", GradientType=1);
    color: #fff;
    text-decoration: none;
}

.card {
    background: #214557;
    color: #fff;
    padding: 20px 60px 20px 0px;
    border-radius: 15px;
    min-height: 275px;
    justify-content: center;
    margin-bottom: 30px;
}

.card>.row {
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
    padding: 15px 15px;
}

.card .content h3 {
    font-weight: bold;
    font-size: 21px;
}

.card .content p {
    font-weight: normal;
    font-size: 16px;
    min-height: 60px;
}

.card .content .primary-btn {
    display: inline-block;
}

.card .content .primary-btn.disabled {
    display: inline-block;
    background: linear-gradient(130deg, rgb(170, 170, 170) 0%, rgb(220, 220, 220) 100%);
    color: rgba(88, 88, 88, 0.5);
    pointer-events: none;
    opacity: 0.4;
}

.card .content .availability {
    font-size: 14px;
    margin-top: 20px;
}

.card .content .availability .fa {
    color: #0FD96D;
    font-size: 12px;
    display: inline-block;
    margin-right: 5px;
}

.table {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0 auto;
}

.user {
    margin: 20px 5px;
    border: solid 2px #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #fff;
    text-align: center;
}

.name_letter {
    width: 100%;
    height: 100%;
    background: #13FCF4;
    text-align: center;
    line-height: 38px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50%;
    text-shadow: '0px 0px 4px rgb(0 0 0 / 25%)';
}

.user img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
}

@media screen and (max-width:992px) {
    .table {
        margin: 70px auto 50px auto;
    }
    .content {
        text-align: center;
    }
    .card>.row {
        padding-top: 25px;
    }
}

/*modal styling*/
.myModal .modal-header {
    padding: 0px 0px;
    border: none;
}

.myModal .close {
    position: absolute;
    right: 10px;
    top: 8px;
    color: #fff;
    z-index: 999;
}

.myModal .modal-content {
    background: #214557;
    height: calc(100vh - 60px);
}

.myModal .modal-body {
    color: #fff;
    padding: 25px 25px 40px 25px;
    height: inherit;
}

.myModal .modal-body h4 {
    font-weight: bold;
    margin-bottom: 15px;
}

.myModal .modal-body p {
    margin-bottom: 5px;
}

.myModal .modal-body>div {
    height: calc(100vh - 110px);
}

.myModal .user-info {
    margin-bottom: 25px;
}

.myModal .modal-body img {
    border-radius: 50%;
    margin-bottom: 15px;
}

.myModal .modal-lg {
    max-width: unset !important;
    max-height: unset !important;
    height: 100vh !important;
}

/*img rotation fixed*/
/*7*/
.table.for7 .chair4 .user img {
    transform: rotate(-140deg);
}

.table.for7 .chair5 .user img {
    transform: rotate(150deg);
}

.table.for7 .chair6 .user img {
    transform: rotate(90deg);
}

.table.for7 .chair7 .user img {
    transform: rotate(45deg);
}

/*6*/
.table.for6 .chair1 .user img {
    transform: rotate(-40deg);
}

.table.for6 .chair2 .user img {
    transform: rotate(-90deg);
}

.table.for6 .chair3 .user img {
    transform: rotate(220deg);
}

.table.for6 .chair4 .user img {
    transform: rotate(145deg);
}

.table.for6 .chair5 .user img {
    transform: rotate(90deg);
}

.table.for6 .chair6 .user img {
    transform: rotate(45deg);
}

/*5*/
.table.for5 .chair1 .user img {
    transform: rotate(-45deg);
}

.table.for5 .chair2 .user img {
    transform: rotate(233deg);
}

.table.for5 .chair3 .user img {
    transform: rotate(152deg);
}

.table.for5 .chair4 .user img {
    transform: rotate(90deg);
}

.table.for5 .chair5 .user img {
    transform: rotate(25deg);
}

/*4*/
.table.for4 .chair1 .user img {
    transform: rotate(0deg);
}

.table.for4 .chair2 .user img {
    transform: rotate(-90deg);
}

.table.for4 .chair3 .user img {
    transform: rotate(180deg);
}

.table.for4 .chair4 .user img {
    transform: rotate(85deg);
}

/*3*/
.table.for3 .chair1 .user img {
    transform: rotate(-45deg);
}

.table.for3 .chair2 .user img {
    transform: rotate(-128deg);
}

.table.for3 .chair3 .user img {
    transform: rotate(90deg);
}

/*2*/
.table.for2 .chair1 .user img {
    transform: rotate(-90deg);
}

.table.for2 .chair2 .user img {
    transform: rotate(90deg);
}

.overlay {
    z-index: 1001;
    position: fixed;
    top: 0px;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    background: rgba(0, 0, 0, 0.6);
}

.loader {
    position: fixed;
    z-index: 1003;
    top: calc(50% - 22px);
    left: calc(50% - 132px);
    background: transparent;
    width: auto;
    height: auto;
}

.loader a {
    font-size: 1.2rem;
    pointer-events: none;
    cursor: none;
}

.loader a img {
    width: 22px;
    margin-right: 5px;
}

.noti_wrapper {
    background-color: #00293E;
    color: #fff;
    width: 300px;
    overflow: hidden;
    word-wrap: break-word;
    border: 1px solid;
    min-height: 60px;
    position: fixed;
    z-index: 9999;
    right: 15px;
    top: 40px;
    border-radius: 15px;
    padding: 10px;
}

.error_noti {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.info_noti {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

/*modal styling*/
#userDetailModal .modal-header {
    padding: 0px 0px;
    border: none;
}
#userDetailModal .close {
    position: absolute;
    right: 10px;
    top: 8px;
    color: #fff;
    z-index: 999;
}
#userDetailModal .modal-content {
    background: #214557;
}
#userDetailModal .modal-body {
    color: #fff;
    padding: 25px 25px 40px 25px;
    overflow: hidden;
}
#userDetailModal .modal-body h4 {
    font-weight: bold;
    margin-bottom: 15px;
}
#userDetailModal .modal-body p {
    margin-bottom: 5px;
}
#userDetailModal .user-info {
    margin-bottom: 25px;
}
#userDetailModal .modal-body img{
    border-radius: 50%;
    margin-bottom: 15px;
    height: 85px;
}

.link-cursor{
    cursor: pointer;
}
.noti_wrapper .noti_cross{
    float: right;
    cursor: pointer;
    font-size: 20px;
    padding: 5px;
}


/*NEW CSS*/
.table {
    position: relative;
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    height: 250px;
    margin: 0 auto;
  }
  
  .table:after {
    content: '';
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background: #221010 !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: 3px;
    margin-top: 3px;
  }
  
  .chair img {
    width: 35px;
    height: 35px;
    opacity: .35;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
  }

  .chair.active {
    z-index: 999;
}
  
  .chair:hover img,
  .chair.active img {
    opacity: 1;
  }
  
  .table .logo {
    position: absolute!important;
    margin: 0px auto !important;
    left: -53px!important;
    top: 85px!important;
    z-index: 999;
    width: 100px!important;
    height: 80px!important;
    object-fit: contain;
    background: #fff;
    padding: 5px;
    border-radius: 10px;
  }
  
  /*--FIXES FOR PREVIOUS CSS--*/
  .user {
      margin: 0 auto !important;
      width: 35px;
      height: 35px;
      line-height: 26px;
      font-weight: bold;
  }
  .chair.active .user {
      position: absolute;
      z-index: 999;
      /*left: 20px;*/
  }
  .roundtable-cards .card {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
  }
  /*--FIXES FOR PREVIOUS CSS - ENDS--*/
  
  /*-----------------GENERAL STYLES ENDS----------------*/
  
  .table.for16 {
    position: relative;
    border-radius: 50%;
    padding: 0;
    list-style: none;
  }
  .table.for16 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
  }
  .table.for16 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(125px) rotate(0deg);
  }
  .table.for16 > *:nth-of-type(2) {
    transform: rotate(22.5deg) translate(125px) rotate(-22.5deg);
  }
  .table.for16 > *:nth-of-type(3) {
    transform: rotate(45deg) translate(125px) rotate(-45deg);
  }
  .table.for16 > *:nth-of-type(4) {
    transform: rotate(67.5deg) translate(125px) rotate(-67.5deg);
  }
  .table.for16 > *:nth-of-type(5) {
    transform: rotate(90deg) translate(125px) rotate(-90deg);
  }
  .table.for16 > *:nth-of-type(6) {
    transform: rotate(112.5deg) translate(125px) rotate(-112.5deg);
  }
  .table.for16 > *:nth-of-type(7) {
    transform: rotate(135deg) translate(125px) rotate(-135deg);
  }
  .table.for16 > *:nth-of-type(8) {
    transform: rotate(157.5deg) translate(125px) rotate(-157.5deg);
  }
  .table.for16 > *:nth-of-type(9) {
    transform: rotate(180deg) translate(125px) rotate(-180deg);
  }
  .table.for16 > *:nth-of-type(10) {
    transform: rotate(202.5deg) translate(125px) rotate(-202.5deg);
  }
  .table.for16 > *:nth-of-type(11) {
    transform: rotate(225deg) translate(125px) rotate(-225deg);
  }
  .table.for16 > *:nth-of-type(12) {
    transform: rotate(247.5deg) translate(125px) rotate(-247.5deg);
  }
  .table.for16 > *:nth-of-type(13) {
    transform: rotate(270deg) translate(125px) rotate(-270deg);
  }
  .table.for16 > *:nth-of-type(14) {
    transform: rotate(292.5deg) translate(125px) rotate(-292.5deg);
  }
  .table.for16 > *:nth-of-type(15) {
    transform: rotate(315deg) translate(125px) rotate(-315deg);
  }
  .table.for16 > *:nth-of-type(16) {
    transform: rotate(337.5deg) translate(125px) rotate(-337.5deg);
  }
  
  .table.for16 .chair1 img {
    transform: rotate(90deg);
  }
  
  .table.for16 .chair1:hover img,
  .table.for16 .chair1.active img {
    transform: rotate(90deg) translateY(-16px);
  }
  
  .table.for16 .chair2 img {
    transform: rotate(111deg);
  }
  
  .table.for16 .chair2:hover img,
  .table.for16 .chair2.active img {
    transform: rotate(111deg) translateX(0px) translateY(-16px);
  }
  
  .table.for16 .chair3 img {
    transform: rotate(135deg);
  }
  
  .table.for16 .chair3:hover img,
  .table.for16 .chair3.active img {
    transform: rotate(135deg) translateX(0px) translateY(-16px);
  }
  
  .table.for16 .chair4 img {
    transform: rotate(158deg);
  }
  
  .table.for16 .chair4:hover img,
  .table.for16 .chair4.active img {
    transform: rotate(158deg) translateX(0px) translateY(-16px);
  }
  
  .table.for16 .chair5 img {
    transform: rotate(181deg);
  }
  
  .table.for16 .chair5:hover img,
  .table.for16 .chair5.active img {
    transform: rotate(181deg) translateX(0px) translateY(-16px);
  }
  
  .table.for16 .chair6 img {
    transform: rotate(204deg);
  }
  
  .table.for16 .chair6:hover img,
  .table.for16 .chair6.active img {
    transform: rotate(204deg) translateX(0px) translateY(-16px);
  }
  
  .table.for16 .chair7 img {
    transform: rotate(226deg);
  }
  
  .table.for16 .chair7:hover img,
  .table.for16 .chair7.active img {
    transform: rotate(226deg) translateX(0px) translateY(-15px);
  }
  
  .table.for16 .chair8 img {
    transform: rotate(248deg);
  }
  
  .table.for16 .chair8:hover img,
  .table.for16 .chair8.active img {
    transform: rotate(248deg) translateX(0px) translateY(-15px);
  }
  
  .table.for16 .chair9 img {
    transform: rotate(270deg);
  }
  
  .table.for16 .chair9:hover img,
  .table.for16 .chair9.active img {
    transform: rotate(270deg) translateX(0px) translateY(-15px);
  }
  
  .table.for16 .chair10 img {
    transform: rotate(292deg);
  }
  
  .table.for16 .chair10:hover img,
  .table.for16 .chair10.active img {
    transform: rotate(292deg) translateX(0px) translateY(-17px);
  }
  
  .table.for16 .chair11 img {
    transform: rotate(315deg);
  }
  
  .table.for16 .chair11:hover img,
  .table.for16 .chair11.active img {
    transform: rotate(315deg) translateX(0px) translateY(-15px);
  }
  
  .table.for16 .chair12 img {
    transform: rotate(335deg);
  }
  
  .table.for16 .chair12:hover img,
  .table.for16 .chair12.active img {
    transform: rotate(335deg) translateX(0px) translateY(-15px);
  }
  
  .table.for16 .chair13 img {
    transform: rotate(0deg);
  }
  
  .table.for16 .chair13:hover img,
  .table.for16 .chair13.active img {
    transform: rotate(0deg) translateX(0px) translateY(-15px);
  }
  
  .table.for16 .chair14 img {
    transform: rotate(20deg);
  }
  
  .table.for16 .chair14:hover img,
  .table.for16 .chair14.active img {
    transform: rotate(20deg) translateX(0px) translateY(-15px);
  }
  
  .table.for16 .chair15 img {
    transform: rotate(44deg);
  }
  
  .table.for16 .chair15:hover img,
  .table.for16 .chair15.active img {
    transform: rotate(44deg) translateX(0px) translateY(-15px);
  }
  
  .table.for16 .chair16 img {
    transform: rotate(65deg);
  }
  
  .table.for16 .chair16:hover img,
  .table.for16 .chair16.active img {
    transform: rotate(65deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 {
    position: relative;
    border-radius: 50%;
    padding: 0;
    list-style: none;
  }
  .table.for14 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
  }
  .table.for14 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(125px) rotate(0deg);
  }
  .table.for14 > *:nth-of-type(2) {
    transform: rotate(25.71429deg) translate(125px) rotate(-25.71429deg);
  }
  .table.for14 > *:nth-of-type(3) {
    transform: rotate(51.42857deg) translate(125px) rotate(-51.42857deg);
  }
  .table.for14 > *:nth-of-type(4) {
    transform: rotate(77.14286deg) translate(125px) rotate(-77.14286deg);
  }
  .table.for14 > *:nth-of-type(5) {
    transform: rotate(102.85714deg) translate(125px) rotate(-102.85714deg);
  }
  .table.for14 > *:nth-of-type(6) {
    transform: rotate(128.57143deg) translate(125px) rotate(-128.57143deg);
  }
  .table.for14 > *:nth-of-type(7) {
    transform: rotate(154.28571deg) translate(125px) rotate(-154.28571deg);
  }
  .table.for14 > *:nth-of-type(8) {
    transform: rotate(180deg) translate(125px) rotate(-180deg);
  }
  .table.for14 > *:nth-of-type(9) {
    transform: rotate(205.71429deg) translate(125px) rotate(-205.71429deg);
  }
  .table.for14 > *:nth-of-type(10) {
    transform: rotate(231.42857deg) translate(125px) rotate(-231.42857deg);
  }
  .table.for14 > *:nth-of-type(11) {
    transform: rotate(257.14286deg) translate(125px) rotate(-257.14286deg);
  }
  .table.for14 > *:nth-of-type(12) {
    transform: rotate(282.85714deg) translate(125px) rotate(-282.85714deg);
  }
  .table.for14 > *:nth-of-type(13) {
    transform: rotate(308.57143deg) translate(125px) rotate(-308.57143deg);
  }
  .table.for14 > *:nth-of-type(14) {
    transform: rotate(334.28571deg) translate(125px) rotate(-334.28571deg);
  }
  
  .table.for14 .chair1 img {
    transform: rotate(90deg);
  }
  
  .table.for14 .chair1:hover img,
  .table.for14 .chair1.active img {
    transform: rotate(90deg) translateY(-16px);
  }
  
  .table.for14 .chair2 img {
    transform: rotate(115deg);
  }
  
  .table.for14 .chair2:hover img,
  .table.for14 .chair2.active img {
    transform: rotate(115deg) translateX(0px) translateY(-16px);
  }
  
  .table.for14 .chair3 img {
    transform: rotate(142deg);
  }
  
  .table.for14 .chair3:hover img,
  .table.for14 .chair3.active img {
    transform: rotate(142deg) translateX(0px) translateY(-16px);
  }
  
  .table.for14 .chair4 img {
    transform: rotate(167deg);
  }
  
  .table.for14 .chair4:hover img,
  .table.for14 .chair4.active img {
    transform: rotate(167deg) translateX(0px) translateY(-16px);
  }
  
  .table.for14 .chair5 img {
    transform: rotate(194deg);
  }
  
  .table.for14 .chair5:hover img,
  .table.for14 .chair5.active img {
    transform: rotate(194deg) translateX(0px) translateY(-16px);
  }
  
  .table.for14 .chair6 img {
    transform: rotate(220deg);
  }
  
  .table.for14 .chair6:hover img,
  .table.for14 .chair6.active img {
    transform: rotate(220deg) translateX(0px) translateY(-16px);
  }
  
  .table.for14 .chair7 img {
    transform: rotate(243deg);
  }
  
  .table.for14 .chair7:hover img,
  .table.for14 .chair7.active img {
    transform: rotate(243deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 .chair8 img {
    transform: rotate(270deg);
  }
  
  .table.for14 .chair8:hover img,
  .table.for14 .chair8.active img {
    transform: rotate(270deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 .chair9 img {
    transform: rotate(295deg);
  }
  
  .table.for14 .chair9:hover img,
  .table.for14 .chair9.active img {
    transform: rotate(295deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 .chair10 img {
    transform: rotate(318deg);
  }
  
  .table.for14 .chair10:hover img,
  .table.for14 .chair10.active img {
    transform: rotate(318deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 .chair11 img {
    transform: rotate(347deg);
  }
  
  .table.for14 .chair11:hover img,
  .table.for14 .chair11.active img {
    transform: rotate(347deg) translateX(0px) translateY(-14px);
  }
  
  .table.for14 .chair12 img {
    transform: rotate(12deg);
  }
  
  .table.for14 .chair12:hover img,
  .table.for14 .chair12.active img {
    transform: rotate(12deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 .chair13 img {
    transform: rotate(38deg);
  }
  
  .table.for14 .chair13:hover img,
  .table.for14 .chair13.active img {
    transform: rotate(38deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 .chair14 img {
    transform: rotate(63deg);
  }
  
  .table.for14 .chair14:hover img,
  .table.for14 .chair14.active img {
    transform: rotate(63deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 .chair15 img {
    transform: rotate(44deg);
  }
  
  .table.for14 .chair15:hover img,
  .table.for14 .chair15.active img {
    transform: rotate(44deg) translateX(0px) translateY(-15px);
  }
  
  .table.for14 .chair16 img {
    transform: rotate(65deg);
  }
  
  .table.for14 .chair16:hover img,
  .table.for14 .chair16.active img {
    transform: rotate(65deg) translateX(0px) translateY(-15px);
  }
  
  .table.for12 {
    position: relative;
    border-radius: 50%;
    padding: 0;
    list-style: none;
  }
  .table.for12 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
  }
  .table.for12 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(125px) rotate(0deg);
  }
  .table.for12 > *:nth-of-type(2) {
    transform: rotate(30deg) translate(125px) rotate(-30deg);
  }
  .table.for12 > *:nth-of-type(3) {
    transform: rotate(60deg) translate(125px) rotate(-60deg);
  }
  .table.for12 > *:nth-of-type(4) {
    transform: rotate(90deg) translate(125px) rotate(-90deg);
  }
  .table.for12 > *:nth-of-type(5) {
    transform: rotate(120deg) translate(125px) rotate(-120deg);
  }
  .table.for12 > *:nth-of-type(6) {
    transform: rotate(150deg) translate(125px) rotate(-150deg);
  }
  .table.for12 > *:nth-of-type(7) {
    transform: rotate(180deg) translate(125px) rotate(-180deg);
  }
  .table.for12 > *:nth-of-type(8) {
    transform: rotate(210deg) translate(125px) rotate(-210deg);
  }
  .table.for12 > *:nth-of-type(9) {
    transform: rotate(240deg) translate(125px) rotate(-240deg);
  }
  .table.for12 > *:nth-of-type(10) {
    transform: rotate(270deg) translate(125px) rotate(-270deg);
  }
  .table.for12 > *:nth-of-type(11) {
    transform: rotate(300deg) translate(125px) rotate(-300deg);
  }
  .table.for12 > *:nth-of-type(12) {
    transform: rotate(330deg) translate(125px) rotate(-330deg);
  }
  
  .table.for12 .chair1 img {
    transform: rotate(90deg);
  }
  
  .table.for12 .chair1:hover img,
  .table.for12 .chair1.active img {
    transform: rotate(90deg) translateY(-16px);
  }
  
  .table.for12 .chair2 img {
    transform: rotate(119deg);
  }
  
  .table.for12 .chair2:hover img,
  .table.for12 .chair2.active img {
    transform: rotate(119deg) translateX(0px) translateY(-16px);
  }
  
  .table.for12 .chair3 img {
    transform: rotate(148deg);
  }
  
  .table.for12 .chair3:hover img,
  .table.for12 .chair3.active img {
    transform: rotate(148deg) translateX(0px) translateY(-16px);
  }
  
  .table.for12 .chair4 img {
    transform: rotate(179deg);
  }
  
  .table.for12 .chair4:hover img,
  .table.for12 .chair4.active img {
    transform: rotate(179deg) translateX(0px) translateY(-16px);
  }
  
  .table.for12 .chair5 img {
    transform: rotate(210deg);
  }
  
  .table.for12 .chair5:hover img,
  .table.for12 .chair5.active img {
    transform: rotate(210deg) translateX(0px) translateY(-16px);
  }
  
  .table.for12 .chair6 img {
    transform: rotate(240deg);
  }
  
  .table.for12 .chair6:hover img,
  .table.for12 .chair6.active img {
    transform: rotate(240deg) translateX(0px) translateY(-16px);
  }
  
  .table.for12 .chair7 img {
    transform: rotate(270deg);
  }
  
  .table.for12 .chair7:hover img,
  .table.for12 .chair7.active img {
    transform: rotate(270deg) translateX(0px) translateY(-15px);
  }
  
  .table.for12 .chair8 img {
    transform: rotate(300deg);
  }
  
  .table.for12 .chair8:hover img,
  .table.for12 .chair8.active img {
    transform: rotate(300deg) translateX(0px) translateY(-15px);
  }
  
  .table.for12 .chair9 img {
    transform: rotate(328deg);
  }
  
  .table.for12 .chair9:hover img,
  .table.for12 .chair9.active img {
    transform: rotate(328deg) translateX(0px) translateY(-15px);
  }
  
  .table.for12 .chair10 img {
    transform: rotate(0deg);
  }
  
  .table.for12 .chair10:hover img,
  .table.for12 .chair10.active img {
    transform: rotate(0deg) translateX(0px) translateY(-15px);
  }
  
  .table.for12 .chair11 img {
    transform: rotate(30deg);
  }
  
  .table.for12 .chair11:hover img,
  .table.for12 .chair11.active img {
    transform: rotate(30deg) translateX(0px) translateY(-14px);
  }
  
  .table.for12 .chair12 img {
    transform: rotate(58deg);
  }
  
  .table.for12 .chair12:hover img,
  .table.for12 .chair12.active img {
    transform: rotate(58deg) translateX(0px) translateY(-15px);
  }
  
  .table.for10 {
    position: relative;
    border-radius: 50%;
    padding: 0;
    list-style: none;
  }
  .table.for10 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
  }
  .table.for10 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(125px) rotate(0deg);
  }
  .table.for10 > *:nth-of-type(2) {
    transform: rotate(36deg) translate(125px) rotate(-36deg);
  }
  .table.for10 > *:nth-of-type(3) {
    transform: rotate(72deg) translate(125px) rotate(-72deg);
  }
  .table.for10 > *:nth-of-type(4) {
    transform: rotate(108deg) translate(125px) rotate(-108deg);
  }
  .table.for10 > *:nth-of-type(5) {
    transform: rotate(144deg) translate(125px) rotate(-144deg);
  }
  .table.for10 > *:nth-of-type(6) {
    transform: rotate(180deg) translate(125px) rotate(-180deg);
  }
  .table.for10 > *:nth-of-type(7) {
    transform: rotate(216deg) translate(125px) rotate(-216deg);
  }
  .table.for10 > *:nth-of-type(8) {
    transform: rotate(252deg) translate(125px) rotate(-252deg);
  }
  .table.for10 > *:nth-of-type(9) {
    transform: rotate(288deg) translate(125px) rotate(-288deg);
  }
  .table.for10 > *:nth-of-type(10) {
    transform: rotate(324deg) translate(125px) rotate(-324deg);
  }
  
  .table.for10 .chair1 img {
    transform: rotate(90deg);
  }
  
  .table.for10 .chair1:hover img,
  .table.for10 .chair1.active img {
    transform: rotate(90deg) translateY(-16px);
  }
  
  .table.for10 .chair2 img {
    transform: rotate(125deg);
  }
  
  .table.for10 .chair2:hover img,
  .table.for10 .chair2.active img {
    transform: rotate(125deg) translateX(0px) translateY(-16px);
  }
  
  .table.for10 .chair3 img {
    transform: rotate(160deg);
  }
  
  .table.for10 .chair3:hover img,
  .table.for10 .chair3.active img {
    transform: rotate(160deg) translateX(0px) translateY(-16px);
  }
  
  .table.for10 .chair4 img {
    transform: rotate(198deg);
  }
  
  .table.for10 .chair4:hover img,
  .table.for10 .chair4.active img {
    transform: rotate(198deg) translateX(0px) translateY(-16px);
  }
  
  .table.for10 .chair5 img {
    transform: rotate(235deg);
  }
  
  .table.for10 .chair5:hover img,
  .table.for10 .chair5.active img {
    transform: rotate(235deg) translateX(0px) translateY(-16px);
  }
  
  .table.for10 .chair6 img {
    transform: rotate(270deg);
  }
  
  .table.for10 .chair6:hover img,
  .table.for10 .chair6.active img {
    transform: rotate(270deg) translateX(0px) translateY(-16px);
  }
  
  .table.for10 .chair7 img {
    transform: rotate(305deg);
  }
  
  .table.for10 .chair7:hover img,
  .table.for10 .chair7.active img {
    transform: rotate(305deg) translateX(0px) translateY(-15px);
  }
  
  .table.for10 .chair8 img {
    transform: rotate(342deg);
  }
  
  .table.for10 .chair8:hover img,
  .table.for10 .chair8.active img {
    transform: rotate(342deg) translateX(0px) translateY(-15px);
  }
  
  .table.for10 .chair9 img {
    transform: rotate(15deg);
  }
  
  .table.for10 .chair9:hover img,
  .table.for10 .chair9.active img {
    transform: rotate(15deg) translateX(0px) translateY(-15px);
  }
  
  .table.for10 .chair10 img {
    transform: rotate(52deg);
  }
  
  .table.for10 .chair10:hover img,
  .table.for10 .chair10.active img {
    transform: rotate(52deg) translateX(0px) translateY(-15px);
  }
  
  .table.for8 {
    position: relative;
    border-radius: 50%;
    padding: 0;
    list-style: none;
  }
  .table.for8 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
  }
  .table.for8 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(125px) rotate(0deg) scale(1.3);
  }
  .table.for8 > *:nth-of-type(2) {
    transform: rotate(45deg) translate(125px) rotate(-45deg) scale(1.3);
  }
  .table.for8 > *:nth-of-type(3) {
    transform: rotate(90deg) translate(125px) rotate(-90deg) scale(1.3);
  }
  .table.for8 > *:nth-of-type(4) {
    transform: rotate(135deg) translate(125px) rotate(-135deg) scale(1.3);
  }
  .table.for8 > *:nth-of-type(5) {
    transform: rotate(180deg) translate(125px) rotate(-180deg) scale(1.3);
  }
  .table.for8 > *:nth-of-type(6) {
    transform: rotate(225deg) translate(125px) rotate(-225deg) scale(1.3);
  }
  .table.for8 > *:nth-of-type(7) {
    transform: rotate(270deg) translate(125px) rotate(-270deg) scale(1.3);
  }
  .table.for8 > *:nth-of-type(8) {
    transform: rotate(315deg) translate(125px) rotate(-315deg) scale(1.3);
  }
  
  .table.for8 .chair1 img {
    transform: rotate(90deg);
  }
  
  .table.for8 .chair1:hover img,
  .table.for8 .chair1.active img {
    transform: rotate(90deg) translateY(-16px);
  }
  
  .table.for8 .chair2 img {
    transform: rotate(135deg);
  }
  
  .table.for8 .chair2:hover img,
  .table.for8 .chair2.active img {
    transform: rotate(135deg) translateX(0px) translateY(-16px);
  }
  
  .table.for8 .chair3 img {
    transform: rotate(180deg);
  }
  
  .table.for8 .chair3:hover img,
  .table.for8 .chair3.active img {
    transform: rotate(180deg) translateX(0px) translateY(-16px);
  }
  
  .table.for8 .chair4 img {
    transform: rotate(225deg);
  }
  
  .table.for8 .chair4:hover img,
  .table.for8 .chair4.active img {
    transform: rotate(225deg) translateX(0px) translateY(-16px);
  }
  
  .table.for8 .chair5 img {
    transform: rotate(270deg);
  }
  
  .table.for8 .chair5:hover img,
  .table.for8 .chair5.active img {
    transform: rotate(270deg) translateX(0px) translateY(-16px);
  }
  
  .table.for8 .chair6 img {
    transform: rotate(315deg);
  }
  
  .table.for8 .chair6:hover img,
  .table.for8 .chair6.active img {
    transform: rotate(315deg) translateX(0px) translateY(-16px);
  }
  
  .table.for8 .chair7 img {
    transform: rotate(0deg);
  }
  
  .table.for8 .chair7:hover img,
  .table.for8 .chair7.active img {
    transform: rotate(0deg) translateX(0px) translateY(-15px);
  }
  
  .table.for8 .chair8 img {
    transform: rotate(45deg);
  }
  
  .table.for8 .chair8:hover img,
  .table.for8 .chair8.active img {
    transform: rotate(45deg) translateX(0px) translateY(-15px);
  }
  
  .table.for6 {
    position: relative;
    border-radius: 50%;
    padding: 0;
    list-style: none;
  }
  .table.for6 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
  }
  .table.for6 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(125px) rotate(0deg) scale(1.3);
  }
  .table.for6 > *:nth-of-type(2) {
    transform: rotate(60deg) translate(125px) rotate(-60deg) scale(1.3);
  }
  .table.for6 > *:nth-of-type(3) {
    transform: rotate(120deg) translate(125px) rotate(-120deg) scale(1.3);
  }
  .table.for6 > *:nth-of-type(4) {
    transform: rotate(180deg) translate(125px) rotate(-180deg) scale(1.3);
  }
  .table.for6 > *:nth-of-type(5) {
    transform: rotate(240deg) translate(125px) rotate(-240deg) scale(1.3);
  }
  .table.for6 > *:nth-of-type(6) {
    transform: rotate(300deg) translate(125px) rotate(-300deg) scale(1.3);
  }
  
  .table.for6 .chair1 img {
    transform: rotate(90deg);
  }
  
  .table.for6 .chair1:hover img,
  .table.for6 .chair1.active img {
    transform: rotate(90deg) translateY(-16px);
  }
  
  .table.for6 .chair2 img {
    transform: rotate(148deg);
  }
  
  .table.for6 .chair2:hover img,
  .table.for6 .chair2.active img {
    transform: rotate(148deg) translateX(0px) translateY(-16px);
  }
  
  .table.for6 .chair3 img {
    transform: rotate(212deg);
  }
  
  .table.for6 .chair3:hover img,
  .table.for6 .chair3.active img {
    transform: rotate(212deg) translateX(0px) translateY(-16px);
  }
  
  .table.for6 .chair4 img {
    transform: rotate(270deg);
  }
  
  .table.for6 .chair4:hover img,
  .table.for6 .chair4.active img {
    transform: rotate(270deg) translateX(0px) translateY(-16px);
  }
  
  .table.for6 .chair5 img {
    transform: rotate(330deg);
  }
  
  .table.for6 .chair5:hover img,
  .table.for6 .chair5.active img {
    transform: rotate(330deg) translateX(0px) translateY(-16px);
  }
  
  .table.for6 .chair6 img {
    transform: rotate(30deg);
  }
  
  .table.for6 .chair6:hover img,
  .table.for6 .chair6.active img {
    transform: rotate(30deg) translateX(0px) translateY(-16px);
  }
  
  .table.for4 {
    position: relative;
    border-radius: 50%;
    padding: 0;
    list-style: none;
  }
  .table.for4 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
  }
  .table.for4 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(125px) rotate(0deg) scale(1.3);
  }
  .table.for4 > *:nth-of-type(2) {
    transform: rotate(90deg) translate(125px) rotate(-90deg) scale(1.3);
  }
  .table.for4 > *:nth-of-type(3) {
    transform: rotate(180deg) translate(125px) rotate(-180deg) scale(1.3);
  }
  .table.for4 > *:nth-of-type(4) {
    transform: rotate(270deg) translate(125px) rotate(-270deg) scale(1.3);
  }
  
  .table.for4 .chair1 img {
    transform: rotate(90deg);
  }
  
  .table.for4 .chair1:hover img,
  .table.for4 .chair1.active img {
    transform: rotate(90deg) translateY(-16px);
  }
  
  .table.for4 .chair2 img {
    transform: rotate(180deg);
  }
  
  .table.for4 .chair2:hover img,
  .table.for4 .chair2.active img {
    transform: rotate(180deg) translateX(0px) translateY(-16px);
  }
  
  .table.for4 .chair3 img {
    transform: rotate(270deg);
  }
  
  .table.for4 .chair3:hover img,
  .table.for4 .chair3.active img {
    transform: rotate(270deg) translateX(0px) translateY(-16px);
  }
  
  .table.for4 .chair4 img {
    transform: rotate(0deg);
  }
  
  .table.for4 .chair4:hover img,
  .table.for4 .chair4.active img {
    transform: rotate(0deg) translateX(0px) translateY(-16px);
  }
  
  .table.for2 {
    position: relative;
    border-radius: 50%;
    padding: 0;
    list-style: none;
  }
  .table.for2 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
  }
  .table.for2 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(125px) rotate(0deg) scale(1.3);
  }
  .table.for2 > *:nth-of-type(2) {
    transform: rotate(180deg) translate(125px) rotate(-180deg) scale(1.3);
  }
  
  .table.for2 .chair1 img {
    transform: rotate(90deg);
  }
  
  .table.for2 .chair1:hover img,
  .table.for2 .chair1.active img {
    transform: rotate(90deg) translateY(-16px);
  }
  
  .table.for2 .chair2 img {
    transform: rotate(270deg);
  }
  
  .table.for2 .chair2:hover img,
  .table.for2 .chair2.active img {
    transform: rotate(270deg) translateX(0px) translateY(-16px);
  }

/*--------FOR ODD NUMBERS--------*/

.table.for3 > *:not(.logo),
.table.for5 > *:not(.logo),
.table.for7 > *:not(.logo),
.table.for9 > *:not(.logo),
.table.for11 > *:not(.logo),
.table.for13 > *:not(.logo),
.table.for15 > *:not(.logo) {
    display: block;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin: -20px;
    width: 40px;
    height: 40px;
}

.table.for3 {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 0;
  list-style: none;
}
.table.for3 > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px;
  width: 40px;
  height: 40px;
}
.table.for3 > *:nth-of-type(1) {
  transform: rotate(0deg) translate(125px) rotate(0deg) scale(1.3);
}
.table.for3 > *:nth-of-type(2) {
  transform: rotate(120deg) translate(120px) rotate(-180deg) scale(1.3);
}
.table.for3 > *:nth-of-type(3) {
  transform: rotate(240deg) translate(125px) rotate(-180deg) scale(1.3);
}

.table.for3 .chair1 img {
  transform: rotate(90deg);
}

.table.for3 .chair1:hover img,
.table.for3 .chair1.active img {
  transform: rotate(90deg) translateY(-16px);
}

.table.for3 .chair2 img {
  transform: rotate(270deg);
}

.table.for3 .chair2:hover img,
.table.for3 .chair2.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for3 .chair3 img {
  transform: rotate(270deg);
}

.table.for3 .chair3:hover img,
.table.for3 .chair3.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for5 {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 0;
  list-style: none;
}
.table.for5 > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px;
  width: 40px;
  height: 40px;
}
.table.for5 > *:nth-of-type(1) {
  transform: rotate(0deg) translate(125px) rotate(0deg) scale(1.3);
}
.table.for5 > *:nth-of-type(2) {
  transform: rotate(72deg) translate(120px) rotate(-180deg) scale(1.3);
}
.table.for5 > *:nth-of-type(3) {
  transform: rotate(144deg) translate(125px) rotate(-180deg) scale(1.3);
}
.table.for5 > *:nth-of-type(4) {
  transform: rotate(216deg) translate(125px) rotate(-180deg) scale(1.3);
}
.table.for5 > *:nth-of-type(5) {
  transform: rotate(288deg) translate(125px) rotate(-180deg) scale(1.3);
}

.table.for5 .chair1 img {
  transform: rotate(90deg);
}

.table.for5 .chair1:hover img,
.table.for5 .chair1.active img {
  transform: rotate(90deg) translateY(-16px);
}

.table.for5 .chair2 img {
  transform: rotate(270deg);
}

.table.for5 .chair2:hover img,
.table.for5 .chair2.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for5 .chair3 img {
  transform: rotate(270deg);
}

.table.for5 .chair3:hover img,
.table.for5 .chair3.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for5 .chair4 img {
  transform: rotate(270deg);
}

.table.for5 .chair4:hover img,
.table.for5 .chair4.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for5 .chair5 img {
  transform: rotate(270deg);
}

.table.for5 .chair5:hover img,
.table.for5 .chair5.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for7 {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 0;
  list-style: none;
}
.table.for7 > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px;
  width: 40px;
  height: 40px;
}
.table.for7 > *:nth-of-type(1) {
  transform: rotate(0deg) translate(125px) rotate(0deg) scale(1.3);
}
.table.for7 > *:nth-of-type(2) {
  transform: rotate(51.42857deg) translate(125px) rotate(-180deg) scale(1.3);
}
.table.for7 > *:nth-of-type(3) {
  transform: rotate(102.85714deg) translate(125px) rotate(-180deg) scale(1.3);
}
.table.for7 > *:nth-of-type(4) {
  transform: rotate(154.28571deg) translate(125px) rotate(-180deg) scale(1.3);
}
.table.for7 > *:nth-of-type(5) {
  transform: rotate(205.71429deg) translate(125px) rotate(-180deg) scale(1.3);
}
.table.for7 > *:nth-of-type(6) {
  transform: rotate(257.14286deg) translate(125px) rotate(-180deg) scale(1.3);
}
.table.for7 > *:nth-of-type(7) {
  transform: rotate(308.57143deg) translate(125px) rotate(-180deg) scale(1.3);
}

.table.for7 .chair1 img {
  transform: rotate(90deg);
}

.table.for7 .chair1:hover img,
.table.for7 .chair1.active img {
  transform: rotate(90deg) translateY(-16px);
}

.table.for7 .chair2 img {
  transform: rotate(270deg);
}

.table.for7 .chair2:hover img,
.table.for7 .chair2.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for7 .chair3 img {
  transform: rotate(270deg);
}

.table.for7 .chair3:hover img,
.table.for7 .chair3.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for7 .chair4 img {
  transform: rotate(270deg);
}

.table.for7 .chair4:hover img,
.table.for7 .chair4.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for7 .chair5 img {
  transform: rotate(270deg);
}

.table.for7 .chair5:hover img,
.table.for7 .chair5.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for7 .chair6 img {
  transform: rotate(270deg);
}

.table.for7 .chair6:hover img,
.table.for7 .chair6.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for7 .chair7 img {
  transform: rotate(270deg);
}

.table.for7 .chair7:hover img,
.table.for7 .chair7.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for9 {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 0;
  list-style: none;
}
.table.for9 > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px;
  width: 40px;
  height: 40px;
}
.table.for9 > *:nth-of-type(1) {
  transform: rotate(0deg) translate(125px) rotate(0deg);
}
.table.for9 > *:nth-of-type(2) {
  transform: rotate(40deg) translate(125px) rotate(-180deg);
}
.table.for9 > *:nth-of-type(3) {
  transform: rotate(80deg) translate(125px) rotate(-180deg);
}
.table.for9 > *:nth-of-type(4) {
  transform: rotate(120deg) translate(125px) rotate(-180deg);
}
.table.for9 > *:nth-of-type(5) {
  transform: rotate(160deg) translate(125px) rotate(-180deg);
}
.table.for9 > *:nth-of-type(6) {
  transform: rotate(200deg) translate(125px) rotate(-180deg);
}
.table.for9 > *:nth-of-type(7) {
  transform: rotate(240deg) translate(125px) rotate(-180deg);
}
.table.for9 > *:nth-of-type(8) {
  transform: rotate(280deg) translate(125px) rotate(-180deg);
}
.table.for9 > *:nth-of-type(9) {
  transform: rotate(320deg) translate(125px) rotate(-180deg);
}

.table.for9 .chair1 img {
  transform: rotate(90deg);
}

.table.for9 .chair1:hover img,
.table.for9 .chair1.active img {
  transform: rotate(90deg) translateY(-16px);
}

.table.for9 .chair2 img {
  transform: rotate(270deg);
}

.table.for9 .chair2:hover img,
.table.for9 .chair2.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for9 .chair3 img {
  transform: rotate(270deg);
}

.table.for9 .chair3:hover img,
.table.for9 .chair3.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for9 .chair4 img {
  transform: rotate(270deg);
}

.table.for9 .chair4:hover img,
.table.for9 .chair4.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for9 .chair5 img {
  transform: rotate(270deg);
}

.table.for9 .chair5:hover img,
.table.for9 .chair5.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for9 .chair6 img {
  transform: rotate(270deg);
}

.table.for9 .chair6:hover img,
.table.for9 .chair6.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for9 .chair7 img {
  transform: rotate(270deg);
}

.table.for9 .chair7:hover img,
.table.for9 .chair7.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for9 .chair8 img {
  transform: rotate(270deg);
}

.table.for9 .chair8:hover img,
.table.for9 .chair8.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for9 .chair9 img {
  transform: rotate(270deg);
}

.table.for9 .chair9:hover img,
.table.for9 .chair9.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 0;
  list-style: none;
}
.table.for11 > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px;
  width: 40px;
  height: 40px;
}
.table.for11 > *:nth-of-type(1) {
  transform: rotate(0deg) translate(125px) rotate(0deg);
}
.table.for11 > *:nth-of-type(2) {
  transform: rotate(32.72727deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(3) {
  transform: rotate(65.45455deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(4) {
  transform: rotate(98.18182deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(5) {
  transform: rotate(130.90909deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(6) {
  transform: rotate(163.63636deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(7) {
  transform: rotate(196.36364deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(8) {
  transform: rotate(229.09091deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(9) {
  transform: rotate(261.81818deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(10) {
  transform: rotate(294.54545deg) translate(125px) rotate(-180deg);
}
.table.for11 > *:nth-of-type(11) {
  transform: rotate(327.27273deg) translate(125px) rotate(-180deg);
}

.table.for11 .chair1 img {
  transform: rotate(90deg);
}

.table.for11 .chair1:hover img,
.table.for11 .chair1.active img {
  transform: rotate(90deg) translateY(-16px);
}

.table.for11 .chair2 img {
  transform: rotate(270deg);
}

.table.for11 .chair2:hover img,
.table.for11 .chair2.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair3 img {
  transform: rotate(270deg);
}

.table.for11 .chair3:hover img,
.table.for11 .chair3.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair4 img {
  transform: rotate(270deg);
}

.table.for11 .chair4:hover img,
.table.for11 .chair4.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair5 img {
  transform: rotate(270deg);
}

.table.for11 .chair5:hover img,
.table.for11 .chair5.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair6 img {
  transform: rotate(270deg);
}

.table.for11 .chair6:hover img,
.table.for11 .chair6.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair7 img {
  transform: rotate(270deg);
}

.table.for11 .chair7:hover img,
.table.for11 .chair7.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair8 img {
  transform: rotate(270deg);
}

.table.for11 .chair8:hover img,
.table.for11 .chair8.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair9 img {
  transform: rotate(270deg);
}

.table.for11 .chair9:hover img,
.table.for11 .chair9.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair10 img {
  transform: rotate(270deg);
}

.table.for11 .chair10:hover img,
.table.for11 .chair10.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for11 .chair11 img {
  transform: rotate(270deg);
}

.table.for11 .chair11:hover img,
.table.for11 .chair11.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 0;
  list-style: none;
}
.table.for13 > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px;
  width: 40px;
  height: 40px;
}
.table.for13 > *:nth-of-type(1) {
  transform: rotate(0deg) translate(125px) rotate(0deg);
}
.table.for13 > *:nth-of-type(2) {
  transform: rotate(27.69231deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(3) {
  transform: rotate(55.38462deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(4) {
  transform: rotate(83.07692deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(5) {
  transform: rotate(110.76923deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(6) {
  transform: rotate(138.46154deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(7) {
  transform: rotate(166.15385deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(8) {
  transform: rotate(193.84615deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(9) {
  transform: rotate(221.53846deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(10) {
  transform: rotate(249.23077deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(11) {
  transform: rotate(276.92308deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(12) {
  transform: rotate(304.61538deg) translate(125px) rotate(-180deg);
}
.table.for13 > *:nth-of-type(13) {
  transform: rotate(332.30769deg) translate(125px) rotate(-180deg);
}

.table.for13 .chair1 img {
  transform: rotate(90deg);
}

.table.for13 .chair1:hover img,
.table.for13 .chair1.active img {
  transform: rotate(90deg) translateY(-16px);
}

.table.for13 .chair2 img {
  transform: rotate(270deg);
}

.table.for13 .chair2:hover img,
.table.for13 .chair2.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair3 img {
  transform: rotate(270deg);
}

.table.for13 .chair3:hover img,
.table.for13 .chair3.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair4 img {
  transform: rotate(270deg);
}

.table.for13 .chair4:hover img,
.table.for13 .chair4.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair5 img {
  transform: rotate(270deg);
}

.table.for13 .chair5:hover img,
.table.for13 .chair5.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair6 img {
  transform: rotate(270deg);
}

.table.for13 .chair6:hover img,
.table.for13 .chair6.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair7 img {
  transform: rotate(270deg);
}

.table.for13 .chair7:hover img,
.table.for13 .chair7.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair8 img {
  transform: rotate(270deg);
}

.table.for13 .chair8:hover img,
.table.for13 .chair8.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair9 img {
  transform: rotate(270deg);
}

.table.for13 .chair9:hover img,
.table.for13 .chair9.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair10 img {
  transform: rotate(270deg);
}

.table.for13 .chair10:hover img,
.table.for13 .chair10.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair11 img {
  transform: rotate(270deg);
}

.table.for13 .chair11:hover img,
.table.for13 .chair11.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair12 img {
  transform: rotate(270deg);
}

.table.for13 .chair12:hover img,
.table.for13 .chair12.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for13 .chair13 img {
  transform: rotate(270deg);
}

.table.for13 .chair13:hover img,
.table.for13 .chair13.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 0;
  list-style: none;
}
.table.for15 > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px;
  width: 40px;
  height: 40px;
}
.table.for15 > *:nth-of-type(1) {
  transform: rotate(0deg) translate(125px) rotate(0deg);
}
.table.for15 > *:nth-of-type(2) {
  transform: rotate(24deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(3) {
  transform: rotate(48deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(4) {
  transform: rotate(72deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(5) {
  transform: rotate(96deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(6) {
  transform: rotate(120deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(7) {
  transform: rotate(144deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(8) {
  transform: rotate(168deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(9) {
  transform: rotate(192deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(10) {
  transform: rotate(216deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(11) {
  transform: rotate(240deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(12) {
  transform: rotate(264deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(13) {
  transform: rotate(288deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(14) {
  transform: rotate(312deg) translate(125px) rotate(-180deg);
}
.table.for15 > *:nth-of-type(15) {
  transform: rotate(336deg) translate(125px) rotate(-180deg);
}

.table.for15 .chair1 img {
  transform: rotate(90deg);
}

.table.for15 .chair1:hover img,
.table.for15 .chair1.active img {
  transform: rotate(90deg) translateY(-16px);
}

.table.for15 .chair2 img {
  transform: rotate(270deg);
}

.table.for15 .chair2:hover img,
.table.for15 .chair2.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair3 img {
  transform: rotate(270deg);
}

.table.for15 .chair3:hover img,
.table.for15 .chair3.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair4 img {
  transform: rotate(270deg);
}

.table.for15 .chair4:hover img,
.table.for15 .chair4.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair5 img {
  transform: rotate(270deg);
}

.table.for15 .chair5:hover img,
.table.for15 .chair5.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair6 img {
  transform: rotate(270deg);
}

.table.for15 .chair6:hover img,
.table.for15 .chair6.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair7 img {
  transform: rotate(270deg);
}

.table.for15 .chair7:hover img,
.table.for15 .chair7.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair8 img {
  transform: rotate(270deg);
}

.table.for15 .chair8:hover img,
.table.for15 .chair8.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair9 img {
  transform: rotate(270deg);
}

.table.for15 .chair9:hover img,
.table.for15 .chair9.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair10 img {
  transform: rotate(270deg);
}

.table.for15 .chair10:hover img,
.table.for15 .chair10.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair11 img {
  transform: rotate(270deg);
}

.table.for15 .chair11:hover img,
.table.for15 .chair11.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair12 img {
  transform: rotate(270deg);
}

.table.for15 .chair12:hover img,
.table.for15 .chair12.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair13 img {
  transform: rotate(270deg);
}

.table.for15 .chair13:hover img,
.table.for15 .chair13.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair14 img {
  transform: rotate(270deg);
}

.table.for15 .chair14:hover img,
.table.for15 .chair14.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}

.table.for15 .chair15 img {
  transform: rotate(270deg);
}

.table.for15 .chair15:hover img,
.table.for15 .chair15.active img {
  transform: rotate(270deg) translateX(0px) translateY(-16px);
}



.meetingModal .modal-header {
  background: #fff;
  display: flex;
  align-items: center;
  min-height: 45px;
  border-radius: 0px;
}
.meetingModal .modal-body {
  padding: 0px 0px !important;
}
.meetingModal .modal-body iframe {
  border: none !important;
  padding: 0px 18px 18px 18px;
}
.meetingModal .modal-header h4 {
  font-size: 18px;
  padding: 0px 15px;
  margin: 0px 0px;
  font-weight: bold;
  color: #000;
}
.meetingModal .modal-header .close {
  background: #fff;
  padding: 10px 15px;
  margin: 0px 0px;
  right: 0;
  top: 0;
  line-height: 20px;
  opacity: 1;
  border-radius: 0px 5px 0px 0px;
  color: #999;
  font-size: 24px !important;
}
.meetingModal {
  max-width: 860px;
  width: 100%;
}
.meetingModal .modal-header .close {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 1px;
}

.meetingModal .modal-content {
  background: #fff;
}

/*FIXES FOR STRANGE IMG POSITIONING*/
.chair.active .user .user-initails {
    margin-top: 0px;
    line-height: 30px;
}
.user img {
    width: 30px !important;
    height: 30px !important;
    object-fit: contain;
    border-radius: 50%;
    transform: unset !important;
    left: 0;
    top: 0;
}
.user .user-initails {
    display: inline-block;
    line-height: 31px;
    margin: 0px 0px !important;
}

.reserved-user-status{
  position: absolute !important;
  width: 8px !important;
  height: 8px !important; 
  border-radius: 20px !important;
  right:1px !important;
  z-index:1;
  border:1px solid white;
}

.moderated-by-text{
  cursor: pointer;
  color: lightblue;
  margin-left: 8px;
  font-size: 14px !important;
  text-decoration: underline;
}

@media screen and (max-width:767px) {
	.cards-con {
		padding: 0px 0px !important;
	}
	.card {
	    padding: 20px 10px 20px 10px !important;
	    width: 100%;
	}
}